import { useState, useRef } from "react";
import { CouncilMotionCard } from "./CouncilMotionCard";
import { categoryIconMap } from "./MotionsCommon";
import "flowbite";
import InfiniteScroll from "react-infinite-scroll-component";
import { FetchMotionsParamsType } from "../motionsApiSlice";
import { useFetchMotionsQuery } from "../motionsApiSlice";
import ListDetails, {
  ListSectionType,
} from "../../../components/ui/ListDetails/ListDetails";
import { useAppSelector } from "../../../app/hooks";
import Spinner from "../../../components/ui/Loaders/Spinner";
import { useParams } from "react-router-dom";
import useSearchMutationState from "../../AddressSearchSection/hooks/useSearchMutationState";

type Props = {
  collapsible?: boolean;
};

export const MotionsContent = ({ collapsible }: Props) => {
  const {
    mutations: {
      siteInfo: { data: siteInfoResponse, isLoading: siteInfoResponseLoading },
    },
  } = useSearchMutationState();

  const { councilDistrictIn } = useParams<{ councilDistrictIn?: string }>();
  const councilDistrict =
    siteInfoResponse?.council_district ?? Number(councilDistrictIn) ?? 0;

  // cache rtk query cache keys to avoid refetching data
  const cachedCategoriesRef = useRef<Record<string, FetchMotionsParamsType>>(
    {},
  );

  const [fetchMotionsParams, setFetchMotionsParams] =
    useState<FetchMotionsParamsType>({
      currentPage: 1,
      category: Array.from(categoryIconMap)[0][0],
      councilDistrict: councilDistrict,
      perPage: 8,
    });

  const { data, isLoading, isFetching, isError, error } = useFetchMotionsQuery(
    fetchMotionsParams,
    {
      skip: fetchMotionsParams.councilDistrict === 0 || siteInfoResponseLoading,
    },
  );

  const labelCase = (s: string) =>
    s.charAt(0).toUpperCase() + s.slice(1).toLowerCase();

  const incrementPage = () => {
    const newPage = fetchMotionsParams.currentPage + 1;
    setFetchMotionsParams((prev) => ({
      ...prev,
      currentPage: newPage,
    }));
  };

  const handleChangeCategory = (categoryName: string) => {
    cachedCategoriesRef.current[fetchMotionsParams.category] = {
      ...fetchMotionsParams,
    };

    if (cachedCategoriesRef.current[categoryName]) {
      setFetchMotionsParams(cachedCategoriesRef.current[categoryName]);
    } else {
      setFetchMotionsParams({
        ...fetchMotionsParams,
        category: categoryName,
        currentPage: 1,
      });
    }
  };

  const listSection: ListSectionType = {
    items: Array.from(categoryIconMap).map(([name]) => {
      return {
        desc: labelCase(name),
        counter:
          isFetching || data?.total === 0
            ? ""
            : `${data?.total} document${data?.total === 1 ? "" : "s"} available`,
        onClick: () => handleChangeCategory(name),
      };
    }),
  };
  const scrollTarget = "motionsScroll";

  return (
    <>
      <ListDetails
        list={[listSection]}
        title="Motions Results"
        scrollableTarget={scrollTarget}
        loadingDetails={isFetching && fetchMotionsParams.currentPage === 1}
        errorDetails={isError ? JSON.stringify(error) : undefined}
        collapsible={collapsible}
      >
        <>
          {data && data.motions.length === 0 && (
            <p className="body-font flex h-full grow items-center justify-center">
              No motions found
            </p>
          )}
          {data && data.motions.length > 0 && (
            <InfiniteScroll
              dataLength={data?.motions?.length ?? 0}
              next={incrementPage}
              hasMore={fetchMotionsParams?.currentPage < data?.lastPage}
              style={{
                maxWidth: "100%",
                overflowX: "clip",
              }}
              loader={
                isFetching && fetchMotionsParams.currentPage !== 1 ? (
                  <Spinner className="mx-auto my-16 block" />
                ) : (
                  <></>
                )
              }
              endMessage={<p className="h-12"></p>}
              scrollableTarget={scrollTarget}
            >
              <div className="mt-2.5 flex flex-col gap-2.5">
                {data?.motions?.map((motion, i) => (
                  <CouncilMotionCard motion={motion} key={motion.id + i} />
                ))}
              </div>
            </InfiniteScroll>
          )}
        </>
      </ListDetails>
    </>
  );
};
