import { useAppSelector } from "../../../../app/hooks";
import * as LosAngeles from "../../../../regiondata/losangeles";
import { TriangleDown16Filled } from "@fluentui/react-icons";
import { useState } from "react";
import { twMerge } from "tailwind-merge";
import CollapsibleSection from "./CollapsibleSection";
import { Control } from "react-hook-form";
import { AddressSearchFormType } from "../../AddressSearchSection";
import useSearchMutationState from "../../hooks/useSearchMutationState";
import RHFCheckbox from "../../../../components/forms/RHFWrappers/RHFCheckbox";
import { getDropdownAriaLabels } from "../../../../helpers/ariaLabels";
import useIsDemo from "../../../../hooks/useIsDemo";

type Props = {
  control: Control<AddressSearchFormType, any>;
};

export default function SpecialityDataSources({ control }: Props) {
  const [isOpen, setIsOpen] = useState(true);
  const isDemo = useIsDemo();

  const dropdownLabels = getDropdownAriaLabels({
    title: "permit type",
    isOpen: isOpen,
  });

  const { isLoading: isDemoLoading } = useAppSelector((state) => state.demo);

  const { isLoading: isResponseLoading } = useSearchMutationState();

  const isLoading = isResponseLoading || (isDemo && isDemoLoading) || !isOpen;

  const miscTypeOptions: SelectableSource[] = LosAngeles.miscTypeOptions;

  return (
    <CollapsibleSection
      title={
        <>
          Select data for inclusion: <br /> Specialty Data Sources
        </>
      }
    >
      {miscTypeOptions.map((item, index) => {
        if (index !== 0 && !isOpen) return null;
        return (
          <div
            key={item.id}
            className="flex w-full items-start"
            aria-label={dropdownLabels.groupingLabel}
          >
            <RHFCheckbox
              control={control}
              name="miscTypeSelected"
              value={item.id}
              label={item.name}
              isDisabled={isLoading}
              className="w-full"
            />
            {index === 0 && (
              <button
                type="button"
                onClick={() => setIsOpen(!isOpen)}
                className="h-6 w-6 flex-shrink-0 translate-x-2"
                aria-label={dropdownLabels.currentLabel}
                aria-expanded={isOpen}
              >
                <TriangleDown16Filled
                  className={twMerge(
                    "mb-[3px] text-rf-dark-brown duration-300",
                    !isOpen && "rotate-90",
                  )}
                />
              </button>
            )}
          </div>
        );
      })}
    </CollapsibleSection>
  );
}
