import React from "react";
import { ListItemType } from "./ListDetails";

type Props = ListItemType & {
  selectedIndex: string;
  index: string;
  handleSetIndex: (index: string) => void;
  isLoading?: boolean;
  activeColor?: string;
};

export default function ListButton({
  desc,
  citation,
  counter,
  index,
  selectedIndex,
  isLoading,
  onClick,
  handleSetIndex,
  activeColor,
}: Props) {
  const isSelected = selectedIndex === index;
  const activeClr = activeColor ? activeColor : "stone-300";

  return (
    <button
      disabled={isLoading}
      onClick={() => {
        handleSetIndex(index);
        onClick && onClick();
      }}
      className={`${isSelected && `bg-${activeClr}`} listItemPadding flex w-full justify-start gap-2 border-b-[1px] border-${activeClr} text-start duration-300 hover:bg-${activeClr}`}
    >
      <div className="relative">
        <p className={"desc-font break-word line-clamp-2"}>{desc}</p>

        {citation && (
          <p
            className={`citation-font mt-1.5 line-clamp-1 break-all underline lg:mt-3`}
          >
            {citation}
          </p>
        )}

        {isSelected && counter && (
          <p className={"citation-font absolute line-clamp-1 break-all"}>
            {counter}
          </p>
        )}
      </div>
    </button>
  );
}
