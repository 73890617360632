import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { toggleVerticalBodyScroll } from "../../helpers/toggleVerticalBodyScroll";
import { getModalPreset } from "./helpers/presets";
import { BYODManagerProps } from "./modals/BYODManager/BYODManager";
import { MotionsViewerProps } from "./modals/MotionsViewer";

export type ModalSize = "auto" | "xs" | "sm" | "md" | "lg" | "xl" | "full";

type ModalStates = {
  MotionsViewer: {
    type: "MotionsViewer";
    props: MotionsViewerProps;
  };
  SearchError: {
    type: "SearchError";
    props: undefined;
  };
  SavedAddresses: {
    type: "SavedAddresses";
    props: undefined;
  };
  BYODManager: {
    type: "BYODManager";
    props: BYODManagerProps;
  };
};

export type ModalTypes = keyof ModalStates;

type ActiveModal = ModalStates[ModalTypes];

interface ModalState {
  isOpen: boolean;
  title: string;
  currentModal: ActiveModal | null;
  width: ModalSize;
  height: ModalSize;
  mobileSize: ModalSize;
}

const initialState: ModalState = {
  isOpen: false,
  title: "",
  currentModal: null,
  width: "md",
  height: "md",
  mobileSize: "md",
};

const modalSlice = createSlice({
  name: "modal",
  initialState,
  reducers: {
    openModal: (
      state,
      action: PayloadAction<{
        type: ModalTypes;
        props?: ModalStates[ModalTypes]["props"];
        title?: string;
        width?: ModalSize;
        height?: ModalSize;
        mobileSize?: ModalSize;
      }>,
    ) => {
      const preset = getModalPreset(action.payload.type);

      state.isOpen = true;
      state.title = action.payload.title ?? preset.title;
      state.width = action.payload.width ?? preset.size.width;
      state.height = action.payload.height ?? preset.size.height;
      state.mobileSize = action.payload.mobileSize ?? preset.size.mobileSize;
      state.currentModal = {
        type: action.payload.type,
        props: action.payload.props,
      } as ActiveModal;

      toggleVerticalBodyScroll(true);
    },
    closeModal: (state) => {
      state.isOpen = false;
      state.currentModal = null;
      state.title = "";
      toggleVerticalBodyScroll(false);
    },
  },
});

export const { openModal, closeModal } = modalSlice.actions;

export default modalSlice.reducer;
